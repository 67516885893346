import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import { Select } from '@theme-ui/components';
import { FaChevronRight } from 'react-icons/fa';
import { Location, navigate } from '@reach/router';
import qs from 'query-string';
import slugify from 'slugify';
import Layout from '../components/layout';
import UnbulletedList from '../components/unbulleted-list';
import ImageBanner from '../components/full-width-image-banner';
import Metadata from '../components/metadata';
import parseCaseStudyNodes from '../utils/caseStudies';

const ClientListing = ({ caseStudies, client }) => (
  <div
    sx={{
      display: 'grid',
      gridTemplateColumns: [
        '1em [content-start] 1fr 6fr 1fr [content-end] 1em',
        '1em 1fr [content-start] 4fr [content-end] 1fr 1em',
      ],
      mb: 4,
    }}
    id={slugify(client, { lower: true })}
  >
    {caseStudies[0].featuredImage.image && (
      <ImageBanner
        width={caseStudies[0].featuredImage.width}
        src={caseStudies[0].featuredImage.image.publicURL}
        bg={caseStudies[0].featuredImage.bgcolor}
        alt={client}
      />
    )}
    <Styled.h1
      sx={{
        gridColumn: 'content-start / content-end',
      }}
    >
      {client}
    </Styled.h1>
    <UnbulletedList
      sx={{
        gridColumn: 'content-start / content-end',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
        gridGap: 3,
      }}
    >
      {caseStudies.map(({ slug, description, title }) => (
        <li key={title}>
          <Styled.a
            href={slug}
            sx={{
              textDecoration: 'none',
              ':hover p': {
                color: 'primary',
              },
            }}
          >
            <Styled.h3 sx={{ m: 0 }}>{title}</Styled.h3>
            <Styled.p>
              {description}
              <FaChevronRight />
            </Styled.p>
          </Styled.a>
        </li>
      ))}
    </UnbulletedList>
  </div>
);

ClientListing.propTypes = {
  caseStudies: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      featuredImage: PropTypes.shape({
        image: PropTypes.shape({
          publicURL: PropTypes.string.isRequired,
        }),
        width: PropTypes.number.isRequired,
        bgcolor: PropTypes.string,
      }).isRequired,
    }).isRequired,
  ).isRequired,
  client: PropTypes.string.isRequired,
};

export const WorkTemplate = ({ clients, industries, selectedIndustry }) => (
  <React.Fragment>
    {industries.length > 0 && (
      <div
        sx={{
          display: 'flex',
          justifyContent: ['center', 'flex-start', 'center'],
          mt: [-4, -4, -48],
          mb: 3,
          px: 4,
        }}
      >
        <label htmlFor="industries" sx={{ display: 'flex', fontSize: 0 }}>
          <span sx={{ lineHeight: 2.5, mr: 2 }}>Filter by industry</span>
          <Select
            id="industries"
            title="Filter by industry"
            onChange={(e) => navigate(`?industry=${e.target.value}`)}
            value={selectedIndustry}
          >
            <option value="">Any industry</option>
            {[...industries].sort().map((i) => <option key={i}>{i}</option>)}
          </Select>
        </label>
      </div>
    )}
    {Object.keys(clients).map((client) => {
      const caseStudies = clients[client]
        .filter((c) => !selectedIndustry
          || (!c.industries ? false : c.industries.includes(selectedIndustry)))
        .sort((a, b) => (a.weight > b.weight ? 1 : -1));

      if (caseStudies.length > 0) {
        return <ClientListing key={client} caseStudies={caseStudies} client={client} />;
      }
      return null;
    })}
  </React.Fragment>
);

WorkTemplate.propTypes = {
  clients: PropTypes.object.isRequired,
  industries: PropTypes.arrayOf(PropTypes.string),
  selectedIndustry: PropTypes.string,
};

WorkTemplate.defaultProps = {
  industries: [],
  selectedIndustry: '',
};

export const WorkTemplateWithLocation = ({ clients, industries }) => (
  <Location>
    {({ location }) => {
      const queryString = location.search ? qs.parse(location.search) : {};
      // prefer industry coming from state over querystring
      const industry = (location.state && location.state.industry) || queryString.industry;
      const initialIndustry = industries.includes(industry) ? industry : '';

      return (
        <WorkTemplate
          clients={clients}
          industries={industries}
          selectedIndustry={initialIndustry}
        />
      );
    }}
  </Location>
);

WorkTemplateWithLocation.propTypes = {
  clients: PropTypes.object.isRequired,
  industries: PropTypes.arrayOf(PropTypes.string),
};

WorkTemplateWithLocation.defaultProps = {
  industries: [],
};

const WorkPage = ({ data }) => {
  const { mdx: page, caseStudies } = data;
  const { frontmatter: metadata } = page;
  const { industries, groupedByClient } = parseCaseStudyNodes(caseStudies);

  return (
    <Layout>
      <Metadata pageData={metadata} />
      <WorkTemplateWithLocation
        clients={groupedByClient}
        industries={industries}
      />
    </Layout>
  );
};

WorkPage.propTypes = {
  data: PropTypes.shape({
    mdx: PropTypes.object.isRequired,
    caseStudies: PropTypes.shape({
      edges: PropTypes.array,
    }).isRequired,
  }).isRequired,
};

export default WorkPage;

export const pageQuery = graphql`
  query($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        meta_description
      }
    }
    caseStudies: allMdx(
      filter: {frontmatter: {templateKey: {eq: "case-study"}}}
      sort: {fields: frontmatter___client}
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            description
            weight
            client
            industries
            featuredImage {
              image {
                publicURL
              }
              width
              bgcolor
            }
          }
        }
      }
    }
  }
`;
